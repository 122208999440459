import { debounce } from "lodash";

class MobileWindowHeight {
  constructor() {
    this.splash = document.getElementById("splash");
    this.nav = document.getElementById("navbar");
    this.setNavHeight();
    this.events();
  }

  events() {
    window.addEventListener("load", this.setSplashHeight);
    window.addEventListener("touchmove", this.setNavHeight);
    window.addEventListener(
      "resize",
      debounce(() => {
        this.setNavHeight();
      }, 333)
    );
    window.addEventListener(
      "scroll",
      debounce(() => {
        this.setNavHeight();
      }, 100)
    );
  }

  setSplashHeight() {
    // document.documentElement.style.setProperty("--vh", `${this.vh}px`);
    this.splash.style.height = `${window.innerHeight}px`;
    // this.parallaxBody.style.marginTop = `${window.innerHeight - 5}px`;
  }

  setNavHeight() {
    if (window.innerWidth < 768) {
      // document.documentElement.style.setProperty("--vh", `${this.vh}px`);
      this.nav.style.height = `${window.innerHeight}px`;
      // this.parallaxBody.style.marginTop = `${window.innerHeight - 5}px`;
    } else {
      this.nav.style.height = "auto";
    }
  }
}

export default MobileWindowHeight;
