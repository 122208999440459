import "../styles/styles.scss";

import MobileMenu from "./Modules/MobileMenu";
import RevealOnScroll from "./Modules/RevealOnScroll";
import BackToTop from "./Modules/BackToTop";
import MobileWindowHeight from "./Modules/MobileWindowHeight";
import parallaxBody from "./Modules/ParallaxBody";

new MobileMenu();
new RevealOnScroll();
new BackToTop();
new MobileWindowHeight();
new parallaxBody();

// smooth scroll - new (css tricks)
jQuery(document).ready(function ($) {
  // console.log("jQuery smooth scroll script run...");

  // e1 - get the height of the fixed header when the document loads
  let offset = $("#header")[0].getBoundingClientRect().height;
  // console.log("Header height on document ready: ", offset);

  $(window).on("load", function () {
    // e1 - update the height of the fixed header when all the css has loaded
    offset = $("#header")[0].getBoundingClientRect().height;
    // console.log("Header height on page load: ", offset);
  });

  // Select all links with hashes &
  // Remove links that don't actually link to anything
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        let target = $(this.hash);
        target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          // e1 - added var since we are offsetting the target to allow for fixed header
          const targetOffset = target.offset().top - offset;

          $("html, body").animate(
            {
              // e1 - changed to include the var we set above
              scrollTop: targetOffset,
              // below is the original code
              // scrollTop: target.offset().top,
            },
            700,
            function () {
              // Callback after animation
              // Must change focus!
              var target = $(target);
              target.focus();

              if (target.is(":focus")) {
                // Checking if the target was focused
                return false;
              }
              target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable

              // e1 - below is the problem code.  setting the focus again, will cause a jump
              // after the animation.  Added "{ preventScroll: true }" in the focus() method
              // to fix the jump
              target.focus({ preventScroll: true }); // Set focus again
            }
          );
        }
      }
    });
});

if (module.hot) {
  module.hot.accept();
}
