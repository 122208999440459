import debounce from "lodash/debounce";

class MobileMenu {
  constructor() {
    this.mobileMenuButton = document.getElementById("menu-toggle");
    this.navToggleLines = document.querySelector(".nav-toggle__lines");
    this.nav = document.getElementById("navbar");
    // below variable returns an htmlCollection. need to use "for" loop, cannot use "forEach"
    this.body = document.getElementsByTagName("body");
    this.header = document.getElementsByTagName("header");
    this.navLinks = document.querySelectorAll(".nav-link");
    this.events();
  }

  events() {
    this.mobileMenuButton.addEventListener("click", () => {
      this.toggleTheMenu();
    });
    this.nav.addEventListener("click", () => {
      this.removeOpenMenuClasses();
    });
    this.navLinks.forEach((link) => {
      link.addEventListener("click", () => {
        this.removeOpenMenuClasses();
      });
    });
    /**
     * below will reset the menu to normal desktop if the window if
     * resized back up to desktop size while the mobile nav is open
     */
    window.addEventListener(
      "resize",
      debounce(() => {
        if (window.innerWidth >= 768) {
          this.removeOpenMenuClasses();
        }
      }, 333)
    );
  }

  toggleTheMenu() {
    this.nav.classList.toggle("navbar--show");
    this.mobileMenuButton.classList.toggle("nav-toggle--open");
    this.navToggleLines.classList.toggle("nav-toggle__lines--clicked");
    this.body[0].classList.toggle("menu-open");
    this.header[0].classList.toggle("header--menu-open");
  }

  removeOpenMenuClasses() {
    this.nav.classList.remove("navbar--show");
    this.mobileMenuButton.classList.remove("nav-toggle--open");
    this.navToggleLines.classList.remove("nav-toggle__lines--clicked");
    this.body[0].classList.remove("menu-open");
    this.header[0].classList.remove("header--menu-open");
  }
}

export default MobileMenu;
