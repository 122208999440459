import throttle from "lodash/throttle";

class parallaxBody {
  constructor() {
    //  splash background
    this.splashBg = document.getElementById("splash-image");

    // splash content
    this.splashText = document.getElementById("splash-text-container");
    // this.splashCatalogImage = document.getElementById(
    //   "splash-content-image--catalog"
    // );

    // selectors for mobile images
    this.splashMobileImageLeft = document.getElementById(
      "splash-mobile-image-left"
    );
    this.splashMobileImageRight = document.getElementById(
      "splash-mobile-image-right"
    );

    // selectors for individual item images
    this.splashCatalogImageNecklace = document.getElementById(
      "splash-content-image--catalog-necklace"
    );
    this.splashCatalogImageLotion = document.getElementById(
      "splash-content-image--catalog-lotion"
    );
    this.splashCatalogImagePalmBag = document.getElementById(
      "splash-content-image--catalog-palm-bag"
    );
    this.splashCatalogImageAlohaBag = document.getElementById(
      "splash-content-image--catalog-aloha-bag"
    );
    this.splashCatalogImageRing = document.getElementById(
      "splash-content-image--catalog-ring"
    );
    this.splashCustomImageHH = document.getElementById(
      "splash-content-image--custom-hh"
    );
    this.splashCustomImageSunglasses = document.getElementById(
      "splash-content-image--custom-sunglasses"
    );
    this.splashCustomImageCandles = document.getElementById(
      "splash-content-image--custom-candles"
    );
    this.splashCustomImageKeychain = document.getElementById(
      "splash-content-image--custom-keychain"
    );

    // border at top of content body
    this.bodyBorderLeft = document.querySelector(
      ".parallax-scroll-body__top-border-left"
    );
    this.bodyBorderRight = document.querySelector(
      ".parallax-scroll-body__top-border-right"
    );

    // scale for transforms
    this.scale = 0;

    this.scrollThrottle = throttle(this.parallaxSplash, 0).bind(this);
    this.events();
  }

  events() {
    window.addEventListener("scroll", this.scrollThrottle);
  }

  parallaxSplash() {
    /**
     * for some reason the  multiplier actually results in a speed of
     * the total splash multiplier + individual element multiplier.
     * 1 below will equal the splash multiplier + 1
     * .75 below will equal the splash multiplier + .75
     * this is because the transform property will transform in relation
     * to the parent element.  we should try NOT to transform parents
     */

    // pageYOffset = amount scrolled
    // mulitplier: -1-0, -1= moves faster than the page, 0 = move with the page, 1 = fixed in position
    if (window.innerHeight - pageYOffset > 0 && pageYOffset >= 0) {
      this.splashBg.style.transform = `translateY(${
        window.pageYOffset * 0.25
      }px)`;
      // vertical parallax
      // this.splashText.style.transform = `translateY(${
      //   window.pageYOffset * 0.5
      // }px)`;
      // this.splashCustomImage.style.transform = `translateY(${
      //   window.pageYOffset * 0.5
      // }px)`;
      this.splashMobileImageLeft.style.transform = `translateX(-${
        window.pageYOffset * 0.5
      }px) translateY(1rem)`;
      this.splashMobileImageRight.style.transform = `translateX(${
        window.pageYOffset * 0.5
      }px) translateY(1rem)`;

      // images transforms
      this.splashCatalogImageNecklace.style.transform = `translateY(${
        window.pageYOffset * -0.25
      }px)`;
      this.splashCatalogImageLotion.style.transform = `translateY(${
        window.pageYOffset * 0.35
      }px)`;
      this.splashCatalogImagePalmBag.style.transform = `translateX(${
        window.pageYOffset * 0.85
      }px)`;
      this.splashCatalogImageAlohaBag.style.transform = `translateY(${
        window.pageYOffset * -0.65
      }px)`;
      this.splashCatalogImageRing.style.transform = `translateY(${
        window.pageYOffset * -0.45
      }px)`;
      this.splashCustomImageHH.style.transform = `translateX(${
        window.pageYOffset * -0.95
      }px)`;
      this.splashCustomImageSunglasses.style.transform = `translateY(${
        window.pageYOffset * -0.65
      }px)`;
      this.splashCustomImageCandles.style.transform = `translateY(${
        window.pageYOffset * -0.05
      }px)`;
      this.splashCustomImageKeychain.style.transform = `translateX(${
        window.pageYOffset * -0.15
      }px)`;

      this.scale = 1 - window.pageYOffset / window.innerHeight;
      if (this.scale >= 0) {
        this.bodyBorderLeft.style.transform = `scaleX(${this.scale})`;
        this.bodyBorderRight.style.transform = `scaleX(${this.scale})`;
      }
    }
  }
}

export default parallaxBody;
