import throttle from "lodash/throttle";
import debounce from "lodash/debounce";

class RevealOnScroll {
  /* constructor(els, thresholdPercent) { */
  constructor() {
    /**
     * this.itemsToReveal = els;
     * this.thresholdPercent = thresholdPercent;
     * use the above code if you want to select the items when
     * creating a new instance of the class in the index.js file
     */
    this.itemsToReveal = document.querySelectorAll(".ros");
    this.thresholdPercent = 85;
    this.browserHeight = window.innerHeight;
    this.hideInitially();
    this.scrollThrottle = throttle(this.calcCaller, 200).bind(this);
    this.events();
  }

  events() {
    window.addEventListener("scroll", this.scrollThrottle);
    window.addEventListener(
      "resize",
      debounce(() => {
        // console.log("Resize just ran");
        this.browserHeight = window.innerHeight;
      }, 333)
    );
  }

  // use the lodash throttler to save energy
  calcCaller() {
    // console.log("Function ran.");
    this.itemsToReveal.forEach((el) => {
      if (el.isRevealed === false) {
        this.calculateIfScrolledTo(el);
      }
    });
  }

  // calculate if elements have come into "view"
  calculateIfScrolledTo(el) {
    if (window.scrollY + this.browserHeight > el.offsetTop) {
      // console.log("Element was scrolled");
      const scrollPercent =
        (el.getBoundingClientRect().top / this.browserHeight) * 100;
      if (scrollPercent < this.thresholdPercent) {
        el.classList.add("reveal-item--visible");
        el.isRevealed = true;
        if (el.isLastItem) {
          window.removeEventListener("scroll", this.scrollThrottle);
        }
      }
    }
  }

  // hide the elements initially, after elements are loaded in the DOM
  hideInitially() {
    this.itemsToReveal.forEach((el) => {
      el.classList.add("reveal-item");
      // add a property on each element so we can later check if it has been revealed
      el.isRevealed = false;
    });
    this.itemsToReveal[this.itemsToReveal.length - 1].isLastItem = true;
  }
}

export default RevealOnScroll;
